import { initializeApp, FirebaseOptions, FirebaseApp } from "firebase/app";

import {
  child,
  Database,
  DatabaseReference,
  DataSnapshot,
  endAt,
  endBefore,
  equalTo,
  EventType,
  get as fbGet,
  getDatabase,
  limitToLast,
  off,
  onChildAdded,
  onChildChanged,
  onChildMoved,
  onChildRemoved,
  onValue,
  orderByChild,
  push,
  query,
  Query,
  ref,
  serverTimestamp,
  set,
  startAfter,
  startAt,
  update,
  increment,
} from "firebase/database";

import {
  getAuth,
  Auth,
  User,
  signInWithCustomToken,
  UserCredential,
  signOut,
} from "firebase/auth";

import {
  getStorage,
  FirebaseStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
  StorageReference,
} from "firebase/storage";

import { Base64 } from "js-base64";
import { get, keys } from "lodash";

import {
  ISetUserFeedCourseInfo,
  IUserCourseFeedProps,
  UserFromJWTToken,
  TCourseType,
  TLMSPaths,
  TCommonPath,
  TSetUserFeedCourseInfo,
  TQueryValue,
  NuggetClassificationType,
  GamificationAction,
  UploadFileResultType,
} from "./types";

import { getGamificationNuggetTypes } from "./utils";
import { raiseLMSAnalytics } from "./LMSAnalytics";
import pLimit from "promise-limit";

const throwErrorWhenTimeOut = (
  ms: number,
  message: string = "Request timeout!"
): Promise<void> => {
  return new Promise((_res, rej) => {
    setTimeout(() => rej(new Error(message)), ms);
  });
};

const noop = () => {};

type FirebaseAPIConfig = FirebaseOptions;
class FirebaseAPI {
  app: FirebaseApp;
  config: FirebaseAPIConfig;
  db: Database;
  auth: Auth;
  organization: string = "na";
  user: User | null = null;
  storage: FirebaseStorage;

  constructor(
    config: FirebaseAPIConfig,
    appName: string,
    authChangedCB?: (user: User | null) => void | undefined
  ) {
    const fbConfig = config;
    this.config = fbConfig;
    const app = initializeApp(config, appName);
    this.app = app;
    this.db = getDatabase(app);
    this.auth = getAuth(app);
    this.storage = getStorage(app);

    this.auth.onAuthStateChanged(async (authUser) => {
      this.user = authUser;
      if (authUser) {
        const { organization: org, ...details } =
          await FirebaseAPI.getTokenUserDetails(authUser);

        this.organization = org ?? "na";
        authChangedCB && authChangedCB(authUser);
      } else {
        this.organization = "na";
        authChangedCB && authChangedCB(authUser);
      }
    });
  }

  static decodeJWT(token: string): object {
    return JSON.parse(Base64.decode(token.split(".")[1] || "{}"));
  }

  static getTokenUserDetails = async (
    authUser: User
  ): Promise<UserFromJWTToken> => {
    const token = await authUser.getIdToken();
    return JSON.parse(Base64.decode(token.split(".")[1] || "{}"));
  };

  static encodeBase64(value: string): string {
    return Base64.encode(value);
  }

  static decodeBase64(value: string): string {
    return Base64.decode(value);
  }

  getNodeRef(nodePath: string) {
    return ref(this.db, nodePath);
  }

  pushToNode(nodePath: string, payload?: any) {
    return push(this.getNodeRef(nodePath), payload);
  }

  getChild(ref: DatabaseReference, path: string) {
    return child(ref, path);
  }

  queryOrderByChild(ref: DatabaseReference, childName: string) {
    return query(ref, orderByChild(childName));
  }

  queryValue(
    queryRef: Query,
    type: TQueryValue,
    value: number | string | boolean | null,
    key?: string
  ): Query {
    let queryFunction = equalTo;

    switch (type) {
      case "startAt":
        queryFunction = startAt;
        break;
      case "startAfter":
        queryFunction = startAfter;
        break;
      case "endBefore":
        queryFunction = endBefore;
        break;
      case "endAt":
        queryFunction = endAt;
        break;
      case "equalTo":
        queryFunction = equalTo;
        break;
      default:
        break;
    }

    return query(queryRef, queryFunction(value, key));
  }

  getMultiPathCommon(nodePath: string = "") {
    nodePath = nodePath ? `/${nodePath}` : "";
    return ref(this.db, `clientOrganizations/${this.organization}${nodePath}`);
  }

  getPublishedShiftNode(shiftDay: string) {
    return this.getMultiPathCommon(`shifts/days/${shiftDay}`);
  }

  listenOnNodeRef(
    queryNodeRef: Query,
    type: EventType,
    cb: (snapshot: DataSnapshot) => any
  ) {
    let listenerFunction;

    switch (type) {
      case "value":
        listenerFunction = onValue;
        break;
      case "child_added":
        listenerFunction = onChildAdded;
        break;
      case "child_changed":
        listenerFunction = onChildChanged;
        break;
      case "child_removed":
        listenerFunction = onChildRemoved;
        break;
      case "child_moved":
        listenerFunction = onChildMoved;
        break;
      default:
        break;
    }

    if (listenerFunction) {
      return listenerFunction(queryNodeRef, cb);
    }

    return noop;
  }

  offNodeRef(
    ref: DatabaseReference,
    type: EventType,
    cb?: ((snapshot: DataSnapshot) => any) | undefined
  ) {
    off(ref, type, cb);
  }

  getPushKey(nodePath: string = "/tmp"): string | null {
    return push(this.getNodeRef(nodePath)).key;
  }

  getPushKeyRef(
    nodeRef: DatabaseReference = this.getNodeRef("/tmp")
  ): string | null {
    return push(nodeRef).key;
  }

  fbUpdate(nodePath: string, payload: object): Promise<void> {
    return update(this.getNodeRef(nodePath), payload);
  }

  fbSet(
    nodePath: string,
    payload: object | string | boolean | null
  ): Promise<void> {
    return set(this.getNodeRef(nodePath), payload);
  }

  set(
    ref: DatabaseReference,
    payload: object | string | boolean | null
  ): Promise<void> {
    return set(ref, payload);
  }

  getLoggedInUserId(): string | null {
    return this.auth.currentUser?.uid ?? null;
  }

  async waitForRPCResponse(responseNodePath: string): Promise<any> {
    return new Promise((resolve, _reject) => {
      const nodeRef = this.getNodeRef(responseNodePath);
      const handler = async (snap: DataSnapshot) => {
        const response = snap.val() || {};
        const { count = 0, results = [], success, result } = response;
        if (
          (count === (results.count || results.length) && success) ||
          result ||
          success === false ||
          result === false
        ) {
          off(nodeRef, "value", handler);
          resolve(response);

          try {
            await set(nodeRef, null);
          } catch (err) {
            console.log(err);
          }
        }
      };

      onValue(nodeRef, handler);
    });
  }

  async callRPC(
    nodePath: string,
    payload: object,
    timeoutInSecond: number = 15
  ): Promise<any> {
    const RPCCall = this.callRPCWithoutTimeout(nodePath, payload);
    const timeout = throwErrorWhenTimeOut(
      timeoutInSecond * 1000,
      "There seems to be a problem with your network connection!"
    );

    try {
      return await Promise.race([RPCCall, timeout]);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async callRPCWithoutTimeout(nodePath: string, payload: object): Promise<any> {
    const pushKey = this.getPushKey(nodePath);
    const requestNodePath = `${nodePath}/request/${pushKey}`;
    const responseNodePath = `${nodePath}/response/${pushKey}`;

    // Place the request
    await this.fbUpdate(requestNodePath, {
      organization: this.organization,
      ...payload,
      createdAt: Date.now(),
      authUUID: this.getLoggedInUserId(),
      userId: this.getLoggedInUserId(),
    });

    try {
      return await this.waitForRPCResponse(responseNodePath);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async signInWithCustomToken(token: string): Promise<UserCredential> {
    return signInWithCustomToken(this.auth, token);
  }

  async signOut(): Promise<void> {
    if (this.getLoggedInUserId()){
      await this.fbSet(`/deviceIds/${this.getLoggedInUserId()}/web`, null);
      return signOut(this.auth);
    }
  }

  async getSignInToken(
    scoobyToken: string,
    organization: string,
    newToken: string
  ): Promise<string> {
    const payload = {
      scoobyToken,
      organization,
      idToken: newToken,
    };

    const results = await this.callRPC("RPC/token", payload);

    return get(results, "result", false) ? get(results, "token", "") : "";
  }

  async signInToOrg(
    scoobyToken: string,
    organization: string,
    newToken: string
  ): Promise<void> {
    const orgToken = await this.getSignInToken(
      scoobyToken,
      organization,
      newToken
    );
    await this.signInWithCustomToken(orgToken);
  }

  getServerTimestamp() {
    return serverTimestamp();
  }

  getUserAttendanceCacheNode(userId: string) {
    return this.getMultiPathCommon(`attendanceCacheData/${userId}`);
  }

  getCurrentUserAttendanceCacheNode() {
    return this.getUserAttendanceCacheNode(this.user?.uid || "na");
  }

  getUserAttendanceNode(userId: string) {
    return this.getMultiPathCommon(`attendanceEvents/${userId}`);
  }

  getCurrentUserAttendanceNode() {
    return this.getUserAttendanceCacheNode(this.user?.uid || "na");
  }

  getMultiPath(nodePath?: string) {
    const orgPath = `clientOrganizations/${this.organization}`;
    if (nodePath) {
      return `${orgPath}/${nodePath}`;
    }
    return orgPath;
  }

  getCommonPath(type: TCommonPath) {
    switch (type) {
      case "shareRequests":
        return "newShareRequests";
      case "shifts":
        return this.getMultiPath("shifts");
      case "attendanceEvents":
        return this.getMultiPath("attendanceEvents");
      case "attendanceEvents":
        return this.getMultiPath(`attendanceEvents`);
      case "currentUserAttendanceEvents":
        return this.getMultiPath(`attendanceEvents/${this.user?.uid || "na"}`);
      case "attendanceCache":
        return this.getMultiPath(`attendanceCacheData`);
      case "currentUserAttendanceCache":
        return this.getMultiPath(
          `attendanceCacheData/${this.user?.uid || "na"}`
        );
      case "users":
        return this.getMultiPath("users");
      case "formNugget":
        return this.getMultiPath("forms/nuggets");
      case "formUserResponses":
        return this.getMultiPath("forms/UserResponses");
      case "currentUserFormResponses":
        return this.getMultiPath(
          `forms/UserResponses/${this.user?.uid ?? "na"}`
        );
      case "currentUserFormDrafts":
        return this.getMultiPath(`forms/UserDrafts/${this.user?.uid ?? "na"}`);
      case "currentUserFormLookups":
        return this.getMultiPath(`forms/Lookups/${this.user?.uid ?? "na"}`);
      case "currentUserFormReceivedResponses":
        return this.getMultiPath(
          `forms/Lookups/${this.user?.uid ?? "na"}/receivedResponses`
        );
      case "currentUserFormReceivedForms":
        return this.getMultiPath(
          `forms/Lookups/${this.user?.uid ?? "na"}/receivedForms`
        );
      case "groupMembers":
        return this.getMultiPath("groupMembers");
      case "gamifications":
        return this.getMultiPath("gamification/points");
      case "gamificationRequests":
        return "userEvents";
      case "formPaperTrails":
        return this.getMultiPath("forms/FormPaperTrail");
      case "currentUserFeed":
        return this.getMultiPath(`userFeed/${this.getLoggedInUserId()}`);
      case "currentUserCompletedFeed":
        return this.getMultiPath(`completedFeed/${this.getLoggedInUserId()}`);
      case "completedTasks":
        return this.getMultiPath(
          `finishedNewTasks/${this.getLoggedInUserId()}`
        );
      case "completedIssues":
        return this.getMultiPath(`completedTasks/${this.getLoggedInUserId()}`);
      case "mutedIssues":
        return this.getMultiPath(`mutePref/${this.getLoggedInUserId()}/issue`);
      case "nuggets":
        return this.getMultiPath("nuggets");
      default:
        return this.getMultiPath();
    }
  }

  async getValue(nodeRef: Query) {
    const snapshot = await fbGet(nodeRef);
    return snapshot.val();
  }

  async getUserDetails(userId: string) {
    const user = await this.getValue(
      this.getMultiPathCommon(`users/${userId}`)
    );
    if (user) {
      return {
        ...user,
        userId,
      };
    }

    return user;
  }

  getLMSDetails(type: TLMSPaths, courseId?: string): DatabaseReference {
    switch (type) {
      case "myCourseFeed":
        return this.getMultiPathCommon(
          `userFeed/${this.getLoggedInUserId()}/courses`
        );
      case "myCourseFeedById":
        return this.getMultiPathCommon(
          `userFeed/${this.getLoggedInUserId()}/courses/${courseId}`
        );
      case "completedCourseFeed":
        return this.getMultiPathCommon(
          `completedFeed/${this.getLoggedInUserId()}/courses`
        );
      case "courseByIdDetails":
        return this.getMultiPathCommon(`nuggets/courses/${courseId}`);
      case "courseByIdPayload":
        return this.getMultiPathCommon(`nuggets/courses/${courseId}/payload`);
      case "journeyCourses":
        return this.getMultiPathCommon(`nuggets/courses/${courseId}/details`);
    }
  }

  getLMSCourseDetails(courseId: string) {
    return this.getValue(this.getLMSDetails("courseByIdDetails", courseId));
  }

  getLMSCoursePayload(courseId: string) {
    return this.getValue(this.getLMSDetails("courseByIdPayload", courseId));
  }

  getLMSJourneyCourses(courseId: string) {
    return this.getValue(this.getLMSDetails("journeyCourses", courseId));
  }

  getCompletedCourses(courseId: string) {
    return this.getValue(this.getLMSDetails("myCourseFeedById", courseId));
  }

  getUserCourseFeedItems({
    lastCreatedAt,
    limit = 16,
    courseType,
  }: IUserCourseFeedProps) {
    const pathRef = this.getLMSDetails(courseType);
    const orderByQueryRef = this.queryOrderByChild(pathRef, "createdAt");
    const endAtQueryRef = this.queryValue(
      orderByQueryRef,
      "endAt",
      lastCreatedAt || Date.now()
    );
    const limitToLastQueryRef = query(endAtQueryRef, limitToLast(limit));
    return this.getValue(limitToLastQueryRef);
  }

  listenForAddRemoveCourseFeedItems(
    cb: (data: any) => void,
    courseType: TCourseType
  ) {
    const pathRef = this.getLMSDetails(courseType);
    const orderByQueryRef = this.queryOrderByChild(pathRef, "createdAt");
    const endAtQueryRef = this.queryValue(
      orderByQueryRef,
      "startAfter",
      Date.now() - 1000
    );
    const unSubAdded = this.listenOnNodeRef(
      endAtQueryRef,
      "child_added",
      (snapshot) => {
        cb([snapshot.key, snapshot.val()]);
      }
    );
    const unSubRemoved = this.listenOnNodeRef(
      pathRef,
      "child_removed",
      (snapshot) => {
        cb([snapshot.key, null]);
      }
    );
    return () => {
      unSubAdded && unSubAdded();
      unSubRemoved && unSubRemoved();
    };
  }

  async listenForUserFeedChangedFinishedCount(
    cb: (data: any) => void,
    courseId: string
  ) {
    const pathRef = this.getMultiPathCommon(
      `userFeed/${this.getLoggedInUserId()}/courses/${courseId}`
    );
    this.offNodeRef(pathRef, "value");
    this.listenOnNodeRef(pathRef, "value", (snapshot) => {
      cb([snapshot.key, snapshot.val()]);
    });
  }

  async listenForProgressLMS(cb: (data: any) => void, courseId: string) {
    const pathRef = this.getMultiPathCommon(
      `progressLMS/${this.getLoggedInUserId()}/${courseId}`
    );
    const pathQuizRef = this.getMultiPathCommon(
      `progressQuizLMS/${this.getLoggedInUserId()}/${courseId}`
    );
    this.offNodeRef(pathRef, "value");
    const progressLMSRes = this.listenOnNodeRef(
      pathRef,
      "value",
      (snapshot) => {
        cb(["progressLMS", snapshot.val()]);
      }
    );
    this.offNodeRef(pathQuizRef, "value");
    const progressQuizLMSRes = this.listenOnNodeRef(
      pathQuizRef,
      "value",
      (snapshot) => {
        cb(["progressQuizLMS", snapshot.val()]);
      }
    );
    return () => {
      progressLMSRes && progressLMSRes();
      progressQuizLMSRes && progressQuizLMSRes();
    };
  }

  async setUserFeedCourseInfo(
    type: TSetUserFeedCourseInfo,
    payload: Partial<ISetUserFeedCourseInfo>
  ) {
    const {
      courseId,
      userFeed,
      lessonId,
      cardId,
      prevCardId,
      selectedLanguage,
      shareId,
      selectedIndex,
      selectedOption,
      scoreCard,
      dt,
      lang,
      sessionId,
      progSec,
      attempt,
      scromProgress,
      feedType,
      journeyId = null,
      data,
      analyticsCBPayload,
    } = payload;

    let path: string;
    switch (type) {
      case "SetLanguage":
        path = `clientOrganizations/${
          this.organization
        }/${feedType}/${this.getLoggedInUserId()}/courses/${
          journeyId ?? courseId
        }`;
        await Promise.all([
          this.fbUpdate(path, {
            lang: selectedLanguage,
            finishedCount: 0,
          }),
          feedType === "userFeed"
            ? this.resetCourseProgress({
                courseId: courseId || "",
                journeyId: journeyId,
                shareId: shareId,
                isLanguageSwitch: true,
              })
            : Promise.resolve(null),
        ]);
        break;
      case "JourneyStarted": {
        const journeyStartedPayload = {
          journeyId,
          dt,
          orgId: this.organization,
          shareId,
          sessionId,
          st: this.getServerTimestamp(),
          type: "started",
          lang,
          uuid: this.getLoggedInUserId(),
        };
        await raiseLMSAnalytics({
          type: "JourneyStarted",
          fbAPI: this,
          payload: journeyStartedPayload,
        });
        break;
      }
      case "JourneyConsumed": {
        path = `clientOrganizations/${
          this.organization
        }/progressLMS/${this.getLoggedInUserId()}/${journeyId}/consumedAt`;
        await this.fbSet(path, this.getServerTimestamp());
        break;
      }
      case "JourneyCompleted": {
        const journeyCompletedPayload = {
          journeyId,
          dt,
          lang,
          orgId: this.organization,
          sessionId,
          shareId,
          st: this.getServerTimestamp(),
          type: "ended",
          uuid: this.getLoggedInUserId(),
        };
        await raiseLMSAnalytics({
          type: "JourneyCompleted",
          fbAPI: this,
          payload: journeyCompletedPayload,
        });
        break;
      }
      case "CourseStarted": {
        const courseStartedPayload = {
          journeyId,
          courseId,
          dt,
          orgId: this.organization,
          shareId,
          sessionId,
          st: this.getServerTimestamp(),
          type: "started",
          lang,
          uuid: this.getLoggedInUserId(),
        };

        await raiseLMSAnalytics({
          type: "CourseStarted",
          fbAPI: this,
          payload: courseStartedPayload,
        });
        break;
      }
      case "CourseConsumed": {
        const courseConsumedPayload = {
          journeyId,
          courseId,
          dt,
          lang,
          orgId: this.organization,
          sessionId,
          shareId,
          st: this.getServerTimestamp(),
          type: "consumed",
          uuid: this.getLoggedInUserId(),
        };
        path = journeyId
          ? `clientOrganizations/${
              this.organization
            }/progressLMS/${this.getLoggedInUserId()}/${journeyId}/${shareId}/${courseId}/consumedAt`
          : `clientOrganizations/${
              this.organization
            }/progressLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/consumedAt`;
        await this.fbSet(path, this.getServerTimestamp());
        await raiseLMSAnalytics({
          type: "CourseConsumed",
          fbAPI: this,
          payload: courseConsumedPayload,
        });
        break;
      }
      case "CourseCompleted": {
        const courseCompletedPayload = {
          journeyId,
          courseId,
          dt,
          lang,
          orgId: this.organization,
          sessionId,
          shareId,
          st: this.getServerTimestamp(),
          type: "ended",
          uuid: this.getLoggedInUserId(),
        };
        await raiseLMSAnalytics({
          type: "CourseCompleted",
          fbAPI: this,
          payload: courseCompletedPayload,
        });
        break;
      }
      case "LessonStarted": {
        const lessonStartedPayload = {
          journeyId,
          courseId,
          moduleId: lessonId,
          dt,
          lang,
          orgId: this.organization,
          shareId,
          sessionId,
          st: this.getServerTimestamp(),
          type: "started",
          uuid: this.getLoggedInUserId(),
        };
        await raiseLMSAnalytics({
          type: "LessonStarted",
          fbAPI: this,
          payload: lessonStartedPayload,
        });
        break;
      }
      case "LessonConsumed": {
        const lessonConsumedPayload = {
          journeyId,
          courseId,
          dt,
          lang,
          moduleId: lessonId,
          orgId: this.organization,
          sessionId,
          shareId,
          st: this.getServerTimestamp(),
          type: "consumed",
          uuid: this.getLoggedInUserId(),
        };
        path = journeyId
          ? `clientOrganizations/${
              this.organization
            }/progressLMS/${this.getLoggedInUserId()}/${journeyId}/${shareId}/${courseId}/${lessonId}/consumedAt`
          : `clientOrganizations/${
              this.organization
            }/progressLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/${lessonId}/consumedAt`;
        await this.fbSet(path, this.getServerTimestamp());
        await raiseLMSAnalytics({
          type: "LessonStarted",
          fbAPI: this,
          payload: lessonConsumedPayload,
        });
        break;
      }
      case "LessonCompleted": {
        const lessonCompletedPayload = {
          journeyId,
          courseId,
          moduleId: lessonId,
          dt,
          lang,
          orgId: this.organization,
          shareId,
          sessionId,
          st: this.getServerTimestamp(),
          type: "ended",
          uuid: this.getLoggedInUserId(),
        };
        await raiseLMSAnalytics({
          type: "LessonCompleted",
          fbAPI: this,
          payload: lessonCompletedPayload,
        });
        break;
      }
      case "CardStarted": {
        const cardStartedPayload = {
          journeyId,
          cardId,
          prevCardId,
          courseId,
          moduleId: lessonId,
          dt,
          lang,
          orgId: this.organization,
          sessionId,
          shareId,
          st: this.getServerTimestamp(),
          type: "started",
          uuid: this.getLoggedInUserId(),
        };
        await raiseLMSAnalytics({
          type: "CardStarted",
          fbAPI: this,
          payload: cardStartedPayload,
        });
        break;
      }
      case "CardConsumed": {
        const cardConsumedPayload = {
          journeyId,
          cardId,
          courseId,
          dt,
          lang,
          moduleId: lessonId,
          orgId: this.organization,
          sessionId,
          shareId,
          st: this.getServerTimestamp(),
          type: "consumed",
          uuid: this.getLoggedInUserId(),
        };
        path = journeyId
          ? `clientOrganizations/${
              this.organization
            }/progressLMS/${this.getLoggedInUserId()}/${journeyId}/${shareId}/${courseId}/${lessonId}/cardProgress/${cardId}/consumedAt`
          : `clientOrganizations/${
              this.organization
            }/progressLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/${lessonId}/cardProgress/${cardId}/consumedAt`;
        await this.fbSet(path, this.getServerTimestamp());
        await raiseLMSAnalytics({
          type: "CardConsumed",
          fbAPI: this,
          payload: cardConsumedPayload,
        });
        break;
      }
      case "CardCompleted": {
        const cardCompletedPayload = {
          journeyId,
          cardId,
          courseId,
          dt,
          lang,
          moduleId: lessonId,
          orgId: this.organization,
          progSec,
          sessionId,
          shareId,
          st: this.getServerTimestamp(),
          type: "ended",
          uuid: this.getLoggedInUserId(),
        };
        await raiseLMSAnalytics({
          type: "CardCompleted",
          fbAPI: this,
          payload: cardCompletedPayload,
        });
        break;
      }
      case "QuizAnswer": {
        path = journeyId
          ? `clientOrganizations/${
              this.organization
            }/progressQuizLMS/${this.getLoggedInUserId()}/${journeyId}/${shareId}/${courseId}/${lessonId}/cardProgress/${cardId}/res/${selectedIndex}`
          : `clientOrganizations/${
              this.organization
            }/progressQuizLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/${lessonId}/cardProgress/${cardId}/res/${selectedIndex}`;

        const quizAnswerPayload = {
          c: this.getServerTimestamp(),
          r: selectedOption,
        };
        await this.fbSet(path, quizAnswerPayload);

        const analyticsPayload = {
          attempt,
          cardId,
          courseId,
          journeyId,
          data: { question: selectedIndex, response: selectedOption },
          dt,
          moduleId: lessonId,
          orgId: this.organization,
          sessionId,
          shareId,
          st: this.getServerTimestamp(),
          type: "answer",
          uuid: this.getLoggedInUserId(),
        };
        path = `/LMSQuizResponseRequests/${this.getPushKey()}`;
        await this.fbSet(path, analyticsPayload);
        if (
          process?.env?.NODE_ENV === "development" ||
          (globalThis as any).enableDebug
        ) {
          console.group("raiseLMSAnalytics");
          console.debug(type, path, analyticsPayload);
          console.groupEnd();
        }
        break;
      }
      case "QuizAttemptInc": {
        const attemptsPath = journeyId
          ? `clientOrganizations/${
              this.organization
            }/progressQuizLMS/${this.getLoggedInUserId()}/${journeyId}/${shareId}/${courseId}/${lessonId}/cardProgress/${cardId}`
          : `clientOrganizations/${
              this.organization
            }/progressQuizLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/${lessonId}/cardProgress/${cardId}`;
        await this.fbUpdate(attemptsPath, {
          attempted: increment(1),
        });
        break;
      }
      case "QuizScoreUpdate": {
        path = journeyId
          ? `clientOrganizations/${
              this.organization
            }/progressQuizLMS/${this.getLoggedInUserId()}/${journeyId}/${shareId}/${courseId}/${lessonId}/cardProgress/${cardId}`
          : `clientOrganizations/${
              this.organization
            }/progressQuizLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/${lessonId}/cardProgress/${cardId}`;
        if (scoreCard) {
          await this.fbUpdate(path, { s: scoreCard });
        }
        break;
      }
      case "UserFeedReceived": {
        for (const feedCourseId in userFeed) {
          if (feedCourseId !== "undefined") {
            // @ts-ignore
            const element = userFeed[feedCourseId];
            if (element?.createdAt && !element.isReceived) {
              const userFeedReceivedPayload = {
                journeyId,
                courseId: feedCourseId,
                dt: Date.now(),
                orgId: this.organization,
                shareId: element?.shareId,
                st: this.getServerTimestamp(),
                type: "received",
                uuid: this.getLoggedInUserId(),
              };
              await raiseLMSAnalytics({
                type: "Received",
                fbAPI: this,
                payload: userFeedReceivedPayload,
              });
              /* Update isReceived: true */
              const userFeedPath = `clientOrganizations/${
                this.organization
              }/userFeed/${this.getLoggedInUserId()}/courses/${feedCourseId}`;
              await this.fbUpdate(userFeedPath, {
                isReceived: true,
              });
            }
          }
        }
        break;
      }
      case "MoveUserFeedToCompletedFeed": {
        const userFeedPath = `clientOrganizations/${
          this.organization
        }/userFeed/${this.getLoggedInUserId()}/courses/${
          journeyId ?? courseId
        }`;

        const completeFeedPath = `clientOrganizations/${
          this.organization
        }/completedFeed/${this.getLoggedInUserId()}/courses/${
          journeyId ?? courseId
        }`;

        const payload = {
          ...userFeed,
          createdAt: this.getServerTimestamp(),
          lang: null,
        };
        await this.fbSet(completeFeedPath, payload);

        /* @ts-ignore */
        await this.fbSet(userFeedPath, null);
        // await this.resetCourseProgress({
        //   courseId: courseId || "",
        //   journeyId: journeyId,
        //   shareId: shareId,
        //   isLanguageSwitch: false,
        // });
        break;
      }
      case "UpdateFinishCountUserFeed": {
        path = journeyId
          ? `clientOrganizations/${
              this.organization
            }/userFeed/${this.getLoggedInUserId()}/courses/${journeyId}`
          : `clientOrganizations/${
              this.organization
            }/userFeed/${this.getLoggedInUserId()}/courses/${courseId}`;
        this.fbUpdate(path, { finishedCount: increment(1) });
        break;
      }
      case "LMSAnalyticsCallback": {
        const payload: any = {
          ...analyticsCBPayload,
          type: "callback",
          orgId: this.organization,
          st: this.getServerTimestamp(),
          knUserId: this.getLoggedInUserId(),
        };
        if (
          payload?.totalCardsConsumed >=
            payload?.cardsCompletedInCurrentSession ||
          (payload?.quizCards ?? []).length > 0
        ) {
          payload.totalCardsConsumed = Math.min(
            payload.totalCardsConsumed,
            payload.totalCards
          );
          await raiseLMSAnalytics({
            type: "LMSAnalyticsCallback",
            fbAPI: this,
            payload,
          });
        } else {
          console.info("LMSAnalyticsCallback", JSON.stringify(payload));
        }
        break;
      }
      case "ScromProgress": {
        const { shareId: sShareId, courseId: sCourseId } = scromProgress || {};
        path = journeyId
          ? `clientOrganizations/${
              this.organization
            }/distinctScormResponsesLMS/${sCourseId}/${journeyId}/${sShareId}/${this.getLoggedInUserId()}`
          : `clientOrganizations/${
              this.organization
            }/distinctScormResponsesLMS/${sCourseId}/${sShareId}/${this.getLoggedInUserId()}`;
        const rpcLMSPath = `RPC/newLMSResponseRequest/request/${this.getPushKey()}`;
        const rpcPayload = JSON.parse(
          JSON.stringify({
            event: scromProgress?.event,
            journeyId,
            courseId: sCourseId,
            moduleId: scromProgress?.lessonId,
            cardId: scromProgress?.cardId,
            nuggetType: "courses",
            organization: this.organization,
            subType: "scorm",
            timestamp: this.getServerTimestamp(),
            dt: scromProgress?.dt,
            userId: this.getLoggedInUserId(),
            sessionStart: scromProgress?.sessionId,
            progress:
              scromProgress?.event?.progress &&
              JSON.parse(scromProgress?.event?.progress),
          })
        );
        const setScromProgress = await this.fbSet(
          path,
          scromProgress?.event?.progress &&
            JSON.parse(scromProgress?.event?.progress)
        );
        const setScromRpcProgress = await this.fbSet(rpcLMSPath, rpcPayload);
        await Promise.all([setScromProgress, setScromRpcProgress]);
        break;
      }
      case "SurveyAnswer":
        const surveyPayload = {
          cardId,
          courseId,
          journeyId: journeyId ?? null,
          data,
          dt,
          moduleId: lessonId,
          orgId: this.organization,
          sessionId,
          shareId,
          st: this.getServerTimestamp(),
          type: "survey",
          uuid: this.getLoggedInUserId(),
        };
        path = `/LMSQuizResponseRequests/${this.getPushKey()}`;
        this.fbSet(path, surveyPayload);
        break;
      default:
        break;
    }
  }

  async getProgressLMS(courseId: string) {
    return this.getValue(
      this.getMultiPathCommon(
        `progressLMS/${this.getLoggedInUserId()}/${courseId}`
      )
    );
  }

  async getProgressQuizLMS(courseId: string) {
    return this.getValue(
      this.getMultiPathCommon(
        `progressQuizLMS/${this.getLoggedInUserId()}/${courseId}`
      )
    );
  }

  async getLessonAttemptedCount({ courseId, shareId, lessonId, cardId }: any) {
    const attemptedNode = `progressQuizLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/${lessonId}/cardProgress/${cardId}/attempted`;
    return this.getValue(this.getMultiPathCommon(attemptedNode));
  }

  async getScromProgress(courseId: string, shareId: string) {
    const path = `distinctScormResponsesLMS/${courseId}/${shareId}/${this.getLoggedInUserId()}`;
    return this.getValue(this.getMultiPathCommon(path));
  }

  async getIsVideoAutoplayEnabled() {
    const autoplayEnabled = `details/preferences/isAutoPlayVideo`;
    return this.getValue(this.getMultiPathCommon(autoplayEnabled));
  }

  async resetCourseProgress({
    courseId,
    journeyId,
    shareId,
    journeyFinishCount = 0,
    isConsumed = false,
    isLanguageSwitch,
  }: {
    courseId: string;
    journeyId?: string | null;
    shareId?: string;
    journeyFinishCount?: number;
    isConsumed?: boolean;
    isLanguageSwitch: boolean;
  }) {
    const journeyPath = `${journeyId}/${shareId}/${courseId}`;
    const coursePath = `${courseId}/${shareId}`;
    const progressLMSPath = `/clientOrganizations/${
      this.organization
    }/progressLMS/${this.getLoggedInUserId()}/${
      journeyId ? journeyPath : coursePath
    }`;

    const progressQuizLMSPath = `/clientOrganizations/${
      this.organization
    }/progressQuizLMS/${this.getLoggedInUserId()}/${
      journeyId ? journeyPath : coursePath
    }`;

    const UpdateFinishCountUserFeedPath = `clientOrganizations/${
      this.organization
    }/userFeed/${this.getLoggedInUserId()}/courses/${journeyId || courseId}`;

    if (journeyId && journeyFinishCount > 0) {
      if (isConsumed) {
        this.fbUpdate(UpdateFinishCountUserFeedPath, {
          finishedCount: journeyFinishCount - 1,
        });
      }
    } else if (isLanguageSwitch) {
      this.fbUpdate(UpdateFinishCountUserFeedPath, {
        finishedCount: 0,
      });
    }

    /* @ts-ignore */
    await Promise.all([
      this.fbSet(progressLMSPath, null),
      this.fbSet(progressQuizLMSPath, null),
    ]);
  }

  uploadFile(
    file: File,
    path: string,
    progressCB: (progress: number) => void,
    _uploadRef?: StorageReference
  ) {
    return new Promise<string>((res, rej) => {
      let uploadRef = _uploadRef;
      if (!uploadRef) {
        const fileNameSplits = file.name.split(".");
        const ext = fileNameSplits.pop();
        uploadRef = storageRef(
          this.storage,
          `${path}/${fileNameSplits.join(".")}${Date.now()}.${ext}`
        );
      }

      const uploadTask = uploadBytesResumable(uploadRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          progressCB(Math.min(90, progress));
        },
        (error) => {
          rej(error);
        },
        async () => {
          progressCB(100);
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          res(url);
        }
      );
    });
  }

  async uploadFileWithFileDetails(
    file: File,
    path: string,
    progressCB: (progress: number) => void
  ) {
    const fileNameSplits = file.name.split(".");
    const ext = fileNameSplits.pop();
    const uploadRef = storageRef(
      this.storage,
      `${path}/${fileNameSplits.join(".")}${Date.now()}.${ext}`
    );

    const url = await this.uploadFile(file, path, progressCB, uploadRef);

    return {
      bucket: uploadRef.bucket,
      contentType: file.type,
      size: file.size,
      url,
      filename: file.name ?? '-'
    };
  }

  uploadMultipleFiles(
    files: File[],
    path: string,
    progressCB: (progress: number) => void
  ) {
    const progress = files.map(() => 0);
    progressCB(0);
    const limit = pLimit<string>(20);

    return Promise.all(
      files.map((file, fileIndex) =>
        limit(() =>
          this.uploadFile(file, path, (singleProgress) => {
            progress[fileIndex] = singleProgress;
            const averageProgress = Math.round(
              progress.reduce((a, b) => a + b) / progress.length
            );
            progressCB(averageProgress);
          })
        )
      )
    );
  }

  uploadMultipleFilesWithFileDetails(
    files: File[],
    path: string,
    progressCB: (progress: number) => void
  ) {
    const progress = files.map(() => 0);
    progressCB(0);
    const limit = pLimit<UploadFileResultType>(20);
    return Promise.all(
      files.map((file, fileIndex) =>
        limit(() =>
          this.uploadFileWithFileDetails(file, path, (singleProgress) => {
            progress[fileIndex] = singleProgress;
            const averageProgress = Math.round(
              progress.reduce((a, b) => a + b) / progress.length
            );
            progressCB(averageProgress);
          })
        )
      )
    );
  }

  async raiseGamification(
    action: GamificationAction,
    module: NuggetClassificationType,
    nuggetId: string
  ) {
    const types = getGamificationNuggetTypes(module);
    if (!types) {
      return;
    }
    const { type, subType } = types;
    const points = await this.getValue(
      this.getNodeRef(
        `${this.getCommonPath("gamifications")}/${type}/${subType}/${action}`
      )
    );

    if (points !== null) {
      const requestId = `${nuggetId}-${this.getLoggedInUserId()}-${action}`;
      const payload = {
        classificationType: type,
        organization: this.organization,
        userId: this.getLoggedInUserId(),
        nuggetId,
        event: action,
        createdAt: this.getServerTimestamp(),
        nuggetType: type,
        points,
      };

      await this.fbSet(
        `${this.getCommonPath("gamificationRequests")}/${requestId}`,
        payload
      );
    }
  }

  async raiseNuggetAnalytics(nuggetId: string, action: string) {
    console.log(`Raising analytics for ${action} ${nuggetId}`);
  }

  async getGroupMembers(groupId: string) {
    const userIds = await this.getValue(
      this.getChild(
        this.getNodeRef(this.getCommonPath("groupMembers")),
        groupId
      )
    );

    return Promise.all(
      keys(userIds).map((userId) => this.getUserDetails(userId))
    );
  }

  async searchUsersRPC(
    filterText: string,
    options: { include?: string[]; view?: string }
  ) {
    const response = await this.callRPCWithoutTimeout("RPC/search", {
      ...options,
      query: filterText,
      type: "users",
      isMobile: true,
    });

    return response.results ?? [];
  }

  getUserFullName(user: any) {
    return user ? `${user.firstName} ${user.lastName}`.trim() : "";
  }

  getUserFullNameOrAlias(user: any) {
    return user
      ? user.alias ?? `${user.firstName} ${user.lastName}`.trim()
      : "";
  }

  async getUserFullNameFromFB(userId: string) {
    const user = await this.getUserDetails(userId);

    return this.getUserFullName(user);
  }
}

export { FirebaseAPI, User };
