import { useEffect, useState } from 'react';
import {
  UserDetailsInterface,
  OrgConfigInterface,
  useToast,
  FirebaseAPI,
} from '@know/ui';
import { keys } from 'lodash';
import md5 from 'md5';

const QR_CODE_REFRESH_INTERVAL = 30;
// we don't need buffer anymore since mobile will do the buffer
// const BUFFER_EXPIRE_TIME = 10;

export const useAttendanceQRValue = (
  userDetails: UserDetailsInterface | null,
  orgConfig: OrgConfigInterface | null
) => {
  const [qrValue, setQRValue] = useState<string>('');
  const [rawStringComponent, setRawStringComponent] = useState<string>('');
  const [remainingSecond, setRemainingSecond] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);

  const [currentUserLocationId, setCurrentUserLocationId] =
    useState<string>('');
  const [currentSecretKey, setCurrentSecretKey] = useState<string>('');
  const toast = useToast();

  useEffect(() => {
    if (userDetails && orgConfig && !isError) {
      const userLocation = userDetails.location;
      const secretKey = orgConfig.attendanceQRSecretKey;
      const locations = orgConfig.locations;
      const userLocationId =
        keys(locations).find(
          (locationId) => locations[locationId].name === userLocation
        ) ?? '';
      setCurrentUserLocationId(userLocationId);
      setCurrentSecretKey(secretKey);
    }
  }, [isError, orgConfig, userDetails]);

  useEffect(() => {
    if (currentUserLocationId && currentSecretKey && !isError) {
      const interval = setInterval(() => {
        const currentEpochInSeconds = Math.floor(Date.now() / 1000);
        const currentSecond = currentEpochInSeconds % QR_CODE_REFRESH_INTERVAL;
        const secondsToNextRefreshedMark =
          QR_CODE_REFRESH_INTERVAL - currentSecond;

        if (currentUserLocationId) {
          const nextTimeStamp =
            (currentEpochInSeconds + secondsToNextRefreshedMark) * 1000;

          const value = `${currentUserLocationId}-${nextTimeStamp}-${currentSecretKey}`;
          const hashString = md5(value);
          setRawStringComponent(`${currentUserLocationId}-${currentSecretKey}`);
          setQRValue(
            FirebaseAPI.encodeBase64(
              `${currentUserLocationId}.${nextTimeStamp}.${hashString}`
            )
          );
        } else {
          setIsError(true);
          return toast.show({
            title:
              'Attendance QR is not configured properly. Please contact KNOW Support.',
            placement: 'bottom-left',
            ml: '20px',
          });
        }

        setRemainingSecond(QR_CODE_REFRESH_INTERVAL - currentSecond);
      }, 1000);

      return () => clearInterval(interval);
    }

    return () => {};
  }, [
    currentUserLocationId,
    qrValue,
    currentSecretKey,
    toast,
    isError,
    rawStringComponent,
  ]);

  return {
    qrValue,
    remainingSecond,
    isError,
  };
};
